import React from 'react';
//@ts-ignore
import Parse from 'parse';

interface IProps {
  backgroundImage?:string;
  username?:string;
  title?:string;
  subtitle?:string;
}

interface IState {
  user?:any;
  password?:any;
}

class RevealLogin extends React.Component<IProps> {
  public state: IState;

  constructor(props: IProps) {
      super(props);
      this.state = {
          user: '',
          password:''
      };
  }

  login = async () => {
    try {
      const user:any = await Parse.User.logIn(this.props.username,this.state.password);
			if(user){
        window.location.assign('/')
      }
    } catch (error) {
      alert(error.message);
    }
  }
  public render(): JSX.Element {
    return (
      <div>
        <div className="retailer-login background-image-fill" style={{backgroundImage:'url('+this.props.backgroundImage+')', minHeight:"100vh"}}>
          <div className="small-padding flex" style={{alignItems:'center',justifyContent:'center',}}>
            <div className="small-max-width-container white-background medium-padding shadow centered-text" style={{"margin":"auto"}}>
              {this.props.title && <h4 className="retailer-login-title">{this.props.title}</h4>}
              {this.props.subtitle && <p className="subheading">{this.props.subtitle}</p>}
              <input placeholder="enter password" className="retailer-login-input secondary-background black full-width" type="text" name="username" value={this.state.password} onChange={(e) => this.setState({password:e.target.value})}/>
              <div className="retailer-login-button button full-width primary-color-background white" onClick={this.login}>{'Let\'s Go!'}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

//@ts-ignore
export default RevealLogin;
