import React, { Component } from 'react'

export class Loader extends Component {
    render() {
        return (
            <div id="site_loader">
		
                <div className="little_logo">
                    <svg version="1.1" id="Layer_1" x="0px" y="0px"
                            viewBox="0 0 82.2 82.2">
                    
                    <path className="st0" d="M17.6,28.8v24.5c0,3.4,3.5,6.2,7.9,6.2h20.2c2.1-1.9,8.4-8.4,4.4-17.1c0,0,6.5,9.7,4.3,17.1h2.4
                        c4.4,0,7.9-2.8,7.9-6.2V28.8c0-3.4-3.5-6.2-7.9-6.2H25.5C21.1,22.6,17.6,25.4,17.6,28.8z M27.8,33.4c0-1.6,1.6-2.8,3.6-2.8h19.4
                        c2,0,3.6,1.3,3.6,2.8v9.9c-0.3-0.4-0.7-0.8-1-1.2l0,0l0,0c-10-11.4-21.3-11.4-21.3-11.4c8.2,1.4,12,6.5,13.7,11.5
                        c0.7,2.7,0.3,6.6-5.4,9.2h-9c-2,0-3.6-1.3-3.6-2.8V33.4z"/>
                    <path className="st0" d="M41.1,82.2C18.4,82.2,0,63.7,0,41.1C0,18.4,18.5,0,41.1,0s41.1,18.5,41.1,41.1C82.3,63.7,63.8,82.2,41.1,82.2z
                            M41.1,8.1C23,8.1,8.2,22.9,8.2,41S23,73.9,41.1,73.9S74,59.1,74,41S59.3,8.1,41.1,8.1z"/>
                    </svg>
                </div>
                
            </div>
        )
    }
}

export default Loader



