import React from 'react'

// @ts-ignore
function FAQ({ faq, index, toggleFAQ }) {
    return (
        <div
            className={'faq ' + (faq.open ? 'open' : '')}
            key={index}
            onClick={() => toggleFAQ(index)}
        >

            <div className='faq-question always-flex justify'>
                <div>{faq.question}</div>
            </div>
            <div className={faq.size}>
                <div>{faq.answer}</div>
            </div>

        </div>
    )
}

export default FAQ
